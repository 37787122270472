// src/store/category.js

import { defineStore } from 'pinia';
import { http } from './https';
import { LOCAL } from '@/constants';
import helpers from '@/helpers';

export const useCategoryStore = defineStore('category', {
  state: () => ({
    categories: [],
    category: {},
    lang: localStorage.getItem(LOCAL) || helpers.getDomainConfig().firstLang,
    page: 1,
    current_page: 1,
    next_page_url: null

  }),
  getters: {
    getCategoriesData: (state) => state.categories,
    getCategory: (state) => state.category,
    getCurrentPage: (state) => state.current_page,
    getNextPageUrl: (state) => state.next_page_url
  },
  actions: {
    async fetchCategories() {
      await http.get(`/categories`).then(response => {
       this.categories=response.data.categories;
      }).catch(error => {
        console.error(error);
      })
    },

    async fetchCategory(id) {

      await http.get(`/category/${id}?lang=${this.lang}`).then(response => {
        this.category = response.data
       
      }).catch(error => {
        console.error(error);
      })
    },
  },

});
