// i18n.js
import { createI18n } from 'vue-i18n';

const messages = {
  en: require('./locales/en/en.json'),
  ar: require('./locales/ar/ar.json'),
  am: require('./locales/am.json'),
  fa: require('./locales/fa.json'),
  fr: require('./locales/fr.json'),
  om: require('./locales/om.json'),
  so: require('./locales/so.json'),
  ti: require('./locales/ti.json'),
};

const i18n = createI18n({
  locale: 'en', // Default locale
  messages,
});

export default i18n;
